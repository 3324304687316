exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-airbnb-clone-benefits-of-airbnb-clone-js": () => import("./../../../src/pages/airbnb-clone/benefits-of-airbnb-clone.js" /* webpackChunkName: "component---src-pages-airbnb-clone-benefits-of-airbnb-clone-js" */),
  "component---src-pages-airbnb-clone-choosing-the-best-airbnb-clone-js": () => import("./../../../src/pages/airbnb-clone/choosing-the-best-airbnb-clone.js" /* webpackChunkName: "component---src-pages-airbnb-clone-choosing-the-best-airbnb-clone-js" */),
  "component---src-pages-airbnb-clone-cost-of-developing-app-like-airbnb-js": () => import("./../../../src/pages/airbnb-clone/cost-of-developing-app-like-airbnb.js" /* webpackChunkName: "component---src-pages-airbnb-clone-cost-of-developing-app-like-airbnb-js" */),
  "component---src-pages-airbnb-clone-features-js": () => import("./../../../src/pages/airbnb-clone/features.js" /* webpackChunkName: "component---src-pages-airbnb-clone-features-js" */),
  "component---src-pages-airbnb-clone-for-cars-index-js": () => import("./../../../src/pages/airbnb-clone-for-cars/index.js" /* webpackChunkName: "component---src-pages-airbnb-clone-for-cars-index-js" */),
  "component---src-pages-airbnb-clone-index-js": () => import("./../../../src/pages/airbnb-clone/index.js" /* webpackChunkName: "component---src-pages-airbnb-clone-index-js" */),
  "component---src-pages-airbnb-clone-key-features-of-airbnb-clone-js": () => import("./../../../src/pages/airbnb-clone/key-features-of-airbnb-clone.js" /* webpackChunkName: "component---src-pages-airbnb-clone-key-features-of-airbnb-clone-js" */),
  "component---src-pages-airbnb-clone-pricing-js": () => import("./../../../src/pages/airbnb-clone/pricing.js" /* webpackChunkName: "component---src-pages-airbnb-clone-pricing-js" */),
  "component---src-pages-airbnb-clone-questions-related-to-airbnb-clone-js": () => import("./../../../src/pages/airbnb-clone/questions-related-to-airbnb-clone.js" /* webpackChunkName: "component---src-pages-airbnb-clone-questions-related-to-airbnb-clone-js" */),
  "component---src-pages-all-features-of-laundry-delivery-app-solution-js": () => import("./../../../src/pages/all-features-of-laundry-delivery-app-solution.js" /* webpackChunkName: "component---src-pages-all-features-of-laundry-delivery-app-solution-js" */),
  "component---src-pages-amazon-clone-js": () => import("./../../../src/pages/amazon-clone.js" /* webpackChunkName: "component---src-pages-amazon-clone-js" */),
  "component---src-pages-boat-rental-script-index-js": () => import("./../../../src/pages/boat-rental-script/index.js" /* webpackChunkName: "component---src-pages-boat-rental-script-index-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-create-an-app-js": () => import("./../../../src/pages/create-an-app.js" /* webpackChunkName: "component---src-pages-create-an-app-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-doordash-clone-index-js": () => import("./../../../src/pages/doordash-clone/index.js" /* webpackChunkName: "component---src-pages-doordash-clone-index-js" */),
  "component---src-pages-grocery-delivery-script-all-features-js": () => import("./../../../src/pages/grocery-delivery-script/all-features.js" /* webpackChunkName: "component---src-pages-grocery-delivery-script-all-features-js" */),
  "component---src-pages-grocery-delivery-script-index-js": () => import("./../../../src/pages/grocery-delivery-script/index.js" /* webpackChunkName: "component---src-pages-grocery-delivery-script-index-js" */),
  "component---src-pages-grocery-delivery-script-pricing-js": () => import("./../../../src/pages/grocery-delivery-script/pricing.js" /* webpackChunkName: "component---src-pages-grocery-delivery-script-pricing-js" */),
  "component---src-pages-grubhub-clone-index-js": () => import("./../../../src/pages/grubhub-clone/index.js" /* webpackChunkName: "component---src-pages-grubhub-clone-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instacart-clone-all-features-js": () => import("./../../../src/pages/instacart-clone/all-features.js" /* webpackChunkName: "component---src-pages-instacart-clone-all-features-js" */),
  "component---src-pages-instacart-clone-index-js": () => import("./../../../src/pages/instacart-clone/index.js" /* webpackChunkName: "component---src-pages-instacart-clone-index-js" */),
  "component---src-pages-instacart-clone-pricing-js": () => import("./../../../src/pages/instacart-clone/pricing.js" /* webpackChunkName: "component---src-pages-instacart-clone-pricing-js" */),
  "component---src-pages-lyft-clone-index-js": () => import("./../../../src/pages/lyft-clone/index.js" /* webpackChunkName: "component---src-pages-lyft-clone-index-js" */),
  "component---src-pages-multi-delivery-super-app-all-features-js": () => import("./../../../src/pages/multi-delivery-super-app/all-features.js" /* webpackChunkName: "component---src-pages-multi-delivery-super-app-all-features-js" */),
  "component---src-pages-multi-delivery-super-app-index-js": () => import("./../../../src/pages/multi-delivery-super-app/index.js" /* webpackChunkName: "component---src-pages-multi-delivery-super-app-index-js" */),
  "component---src-pages-multi-delivery-super-app-pricing-js": () => import("./../../../src/pages/multi-delivery-super-app/pricing.js" /* webpackChunkName: "component---src-pages-multi-delivery-super-app-pricing-js" */),
  "component---src-pages-ola-clone-index-js": () => import("./../../../src/pages/ola-clone/index.js" /* webpackChunkName: "component---src-pages-ola-clone-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-postmates-clone-index-js": () => import("./../../../src/pages/postmates-clone/index.js" /* webpackChunkName: "component---src-pages-postmates-clone-index-js" */),
  "component---src-pages-pricing-comparison-of-laundry-delivery-app-solution-js": () => import("./../../../src/pages/pricing-comparison-of-laundry-delivery-app-solution.js" /* webpackChunkName: "component---src-pages-pricing-comparison-of-laundry-delivery-app-solution-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rappi-clone-index-js": () => import("./../../../src/pages/rappi-clone/index.js" /* webpackChunkName: "component---src-pages-rappi-clone-index-js" */),
  "component---src-pages-returns-refunds-policy-js": () => import("./../../../src/pages/returns-refunds-policy.js" /* webpackChunkName: "component---src-pages-returns-refunds-policy-js" */),
  "component---src-pages-row-component-js": () => import("./../../../src/pages/RowComponent.js" /* webpackChunkName: "component---src-pages-row-component-js" */),
  "component---src-pages-schedule-a-free-demo-index-js": () => import("./../../../src/pages/schedule-a-free-demo/index.js" /* webpackChunkName: "component---src-pages-schedule-a-free-demo-index-js" */),
  "component---src-pages-sitemap-index-js": () => import("./../../../src/pages/sitemap/index.js" /* webpackChunkName: "component---src-pages-sitemap-index-js" */),
  "component---src-pages-space-rental-script-index-js": () => import("./../../../src/pages/space-rental-script/index.js" /* webpackChunkName: "component---src-pages-space-rental-script-index-js" */),
  "component---src-pages-start-a-rental-business-js": () => import("./../../../src/pages/start-a-rental-business.js" /* webpackChunkName: "component---src-pages-start-a-rental-business-js" */),
  "component---src-pages-taskrabbit-clone-all-features-js": () => import("./../../../src/pages/taskrabbit-clone/all-features.js" /* webpackChunkName: "component---src-pages-taskrabbit-clone-all-features-js" */),
  "component---src-pages-taskrabbit-clone-benefits-of-taskrabbit-clone-js": () => import("./../../../src/pages/taskrabbit-clone/benefits-of-taskrabbit-clone.js" /* webpackChunkName: "component---src-pages-taskrabbit-clone-benefits-of-taskrabbit-clone-js" */),
  "component---src-pages-taskrabbit-clone-choose-the-best-taskrabbit-clone-js": () => import("./../../../src/pages/taskrabbit-clone/choose-the-best-taskrabbit-clone.js" /* webpackChunkName: "component---src-pages-taskrabbit-clone-choose-the-best-taskrabbit-clone-js" */),
  "component---src-pages-taskrabbit-clone-cost-of-developing-app-like-taskrabbit-js": () => import("./../../../src/pages/taskrabbit-clone/cost-of-developing-app-like-taskrabbit.js" /* webpackChunkName: "component---src-pages-taskrabbit-clone-cost-of-developing-app-like-taskrabbit-js" */),
  "component---src-pages-taskrabbit-clone-index-js": () => import("./../../../src/pages/taskrabbit-clone/index.js" /* webpackChunkName: "component---src-pages-taskrabbit-clone-index-js" */),
  "component---src-pages-taskrabbit-clone-key-features-of-taskrabbit-clone-js": () => import("./../../../src/pages/taskrabbit-clone/key-features-of-taskrabbit-clone.js" /* webpackChunkName: "component---src-pages-taskrabbit-clone-key-features-of-taskrabbit-clone-js" */),
  "component---src-pages-taskrabbit-clone-pricing-js": () => import("./../../../src/pages/taskrabbit-clone/pricing.js" /* webpackChunkName: "component---src-pages-taskrabbit-clone-pricing-js" */),
  "component---src-pages-taskrabbit-clone-questions-related-to-taskrabbit-clone-js": () => import("./../../../src/pages/taskrabbit-clone/questions-related-to-taskrabbit-clone.js" /* webpackChunkName: "component---src-pages-taskrabbit-clone-questions-related-to-taskrabbit-clone-js" */),
  "component---src-pages-taxi-app-development-app-development-cost-js": () => import("./../../../src/pages/taxi-app-development/app-development-cost.js" /* webpackChunkName: "component---src-pages-taxi-app-development-app-development-cost-js" */),
  "component---src-pages-taxi-app-development-business-and-revenue-model-js": () => import("./../../../src/pages/taxi-app-development/business-and-revenue-model.js" /* webpackChunkName: "component---src-pages-taxi-app-development-business-and-revenue-model-js" */),
  "component---src-pages-taxi-app-development-choosing-taxi-app-development-company-js": () => import("./../../../src/pages/taxi-app-development/choosing-taxi-app-development-company.js" /* webpackChunkName: "component---src-pages-taxi-app-development-choosing-taxi-app-development-company-js" */),
  "component---src-pages-taxi-app-development-how-taxi-app-works-js": () => import("./../../../src/pages/taxi-app-development/how-taxi-app-works.js" /* webpackChunkName: "component---src-pages-taxi-app-development-how-taxi-app-works-js" */),
  "component---src-pages-taxi-app-development-index-js": () => import("./../../../src/pages/taxi-app-development/index.js" /* webpackChunkName: "component---src-pages-taxi-app-development-index-js" */),
  "component---src-pages-taxi-app-development-taxi-app-features-js": () => import("./../../../src/pages/taxi-app-development/taxi-app-features.js" /* webpackChunkName: "component---src-pages-taxi-app-development-taxi-app-features-js" */),
  "component---src-pages-taxi-app-development-taxi-industry-statistics-js": () => import("./../../../src/pages/taxi-app-development/taxi-industry-statistics.js" /* webpackChunkName: "component---src-pages-taxi-app-development-taxi-industry-statistics-js" */),
  "component---src-pages-taxi-app-development-technology-js": () => import("./../../../src/pages/taxi-app-development/technology.js" /* webpackChunkName: "component---src-pages-taxi-app-development-technology-js" */),
  "component---src-pages-taxi-booking-script-index-js": () => import("./../../../src/pages/taxi-booking-script/index.js" /* webpackChunkName: "component---src-pages-taxi-booking-script-index-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-thumbtack-clone-index-js": () => import("./../../../src/pages/thumbtack-clone/index.js" /* webpackChunkName: "component---src-pages-thumbtack-clone-index-js" */),
  "component---src-pages-turo-clone-index-js": () => import("./../../../src/pages/turo-clone/index.js" /* webpackChunkName: "component---src-pages-turo-clone-index-js" */),
  "component---src-pages-uber-clone-all-features-js": () => import("./../../../src/pages/uber-clone/all-features.js" /* webpackChunkName: "component---src-pages-uber-clone-all-features-js" */),
  "component---src-pages-uber-clone-benefits-of-uber-clone-js": () => import("./../../../src/pages/uber-clone/benefits-of-uber-clone.js" /* webpackChunkName: "component---src-pages-uber-clone-benefits-of-uber-clone-js" */),
  "component---src-pages-uber-clone-common-queries-related-to-uber-clone-js": () => import("./../../../src/pages/uber-clone/common-queries-related-to-uber-clone.js" /* webpackChunkName: "component---src-pages-uber-clone-common-queries-related-to-uber-clone-js" */),
  "component---src-pages-uber-clone-how-to-choose-the-best-uber-clone-app-js": () => import("./../../../src/pages/uber-clone/how-to-choose-the-best-uber-clone-app.js" /* webpackChunkName: "component---src-pages-uber-clone-how-to-choose-the-best-uber-clone-app-js" */),
  "component---src-pages-uber-clone-index-js": () => import("./../../../src/pages/uber-clone/index.js" /* webpackChunkName: "component---src-pages-uber-clone-index-js" */),
  "component---src-pages-uber-clone-key-features-of-uber-clone-app-js": () => import("./../../../src/pages/uber-clone/key-features-of-Uber-clone-app.js" /* webpackChunkName: "component---src-pages-uber-clone-key-features-of-uber-clone-app-js" */),
  "component---src-pages-uber-clone-pricing-js": () => import("./../../../src/pages/uber-clone/pricing.js" /* webpackChunkName: "component---src-pages-uber-clone-pricing-js" */),
  "component---src-pages-uber-clone-uber-clone-app-development-cost-js": () => import("./../../../src/pages/uber-clone/uber-clone-app-development-cost.js" /* webpackChunkName: "component---src-pages-uber-clone-uber-clone-app-development-cost-js" */),
  "component---src-pages-uber-clone-whitelabel-uber-clone-app-js": () => import("./../../../src/pages/uber-clone/whitelabel-uber-clone-app.js" /* webpackChunkName: "component---src-pages-uber-clone-whitelabel-uber-clone-app-js" */),
  "component---src-pages-uber-for-handyman-app-all-features-js": () => import("./../../../src/pages/uber-for-handyman-app/all-features.js" /* webpackChunkName: "component---src-pages-uber-for-handyman-app-all-features-js" */),
  "component---src-pages-uber-for-handyman-app-index-js": () => import("./../../../src/pages/uber-for-handyman-app/index.js" /* webpackChunkName: "component---src-pages-uber-for-handyman-app-index-js" */),
  "component---src-pages-uber-for-handyman-app-pricing-js": () => import("./../../../src/pages/uber-for-handyman-app/pricing.js" /* webpackChunkName: "component---src-pages-uber-for-handyman-app-pricing-js" */),
  "component---src-pages-uber-for-laundry-index-js": () => import("./../../../src/pages/uber-for-laundry/index.js" /* webpackChunkName: "component---src-pages-uber-for-laundry-index-js" */),
  "component---src-pages-uber-for-medicine-delivery-app-development-all-features-js": () => import("./../../../src/pages/uber-for-medicine-delivery-app-development/all-features.js" /* webpackChunkName: "component---src-pages-uber-for-medicine-delivery-app-development-all-features-js" */),
  "component---src-pages-uber-for-medicine-delivery-app-development-index-js": () => import("./../../../src/pages/uber-for-medicine-delivery-app-development/index.js" /* webpackChunkName: "component---src-pages-uber-for-medicine-delivery-app-development-index-js" */),
  "component---src-pages-uber-for-medicine-delivery-app-development-pricing-js": () => import("./../../../src/pages/uber-for-medicine-delivery-app-development/pricing.js" /* webpackChunkName: "component---src-pages-uber-for-medicine-delivery-app-development-pricing-js" */),
  "component---src-pages-uber-for-road-assistance-all-features-js": () => import("./../../../src/pages/uber-for-road-assistance/all-features.js" /* webpackChunkName: "component---src-pages-uber-for-road-assistance-all-features-js" */),
  "component---src-pages-uber-for-road-assistance-index-js": () => import("./../../../src/pages/uber-for-road-assistance/index.js" /* webpackChunkName: "component---src-pages-uber-for-road-assistance-index-js" */),
  "component---src-pages-uber-for-road-assistance-pricing-comparison-js": () => import("./../../../src/pages/uber-for-road-assistance/pricing-comparison.js" /* webpackChunkName: "component---src-pages-uber-for-road-assistance-pricing-comparison-js" */),
  "component---src-pages-ubereats-clone-common-queries-related-uber-eats-clone-js": () => import("./../../../src/pages/ubereats-clone/common-queries-related-uber-eats-clone.js" /* webpackChunkName: "component---src-pages-ubereats-clone-common-queries-related-uber-eats-clone-js" */),
  "component---src-pages-ubereats-clone-how-to-choose-uber-eats-clone-js": () => import("./../../../src/pages/ubereats-clone/how-to-choose-uber-eats-clone.js" /* webpackChunkName: "component---src-pages-ubereats-clone-how-to-choose-uber-eats-clone-js" */),
  "component---src-pages-ubereats-clone-index-js": () => import("./../../../src/pages/ubereats-clone/index.js" /* webpackChunkName: "component---src-pages-ubereats-clone-index-js" */),
  "component---src-pages-ubereats-clone-key-features-of-uber-eats-clone-js": () => import("./../../../src/pages/ubereats-clone/key-features-of-uber-eats-clone.js" /* webpackChunkName: "component---src-pages-ubereats-clone-key-features-of-uber-eats-clone-js" */),
  "component---src-pages-vacation-rental-script-index-js": () => import("./../../../src/pages/vacation-rental-script/index.js" /* webpackChunkName: "component---src-pages-vacation-rental-script-index-js" */),
  "component---src-pages-vrbo-clone-index-js": () => import("./../../../src/pages/vrbo-clone/index.js" /* webpackChunkName: "component---src-pages-vrbo-clone-index-js" */),
  "component---src-pages-whatsapp-clone-index-js": () => import("./../../../src/pages/whatsapp-clone/index.js" /* webpackChunkName: "component---src-pages-whatsapp-clone-index-js" */)
}

