require('lazysizes')
require('bootstrap/dist/css/bootstrap.min.css')
require('./src/components/swiper.css')
require('./src/components/swiper.min.css')
require('./src/components/slick.min.css')
require('./src/components/slick-theme.min.css')
require('./src/styles/variable.css')
require('./src/styles/layout.css')
require('./src/styles/responsive.css')
require('./src/styles/global.css')
require('./src/styles/global-responsive.css')
require('./src/styles/globalFooter.css')
require('./src/styles/BigSaleFooterSticky.css')




const React = require("react");

const headElement = document.getElementsByTagName('head');
const script = document.createElement('script');
script.defer = true;
script.innerHTML = `var sc_project=12385388; var sc_invisible=1; var sc_security="8532cc2d"; var sc_https=1;`;

headElement[0].appendChild(script);

const script2 = document.createElement('script');
script2.src = `https://www.statcounter.com/counter/counter.js`;

headElement[0].appendChild(script2);

// Wraps every page in a component
exports.wrapPageElement = ({ element }) => {
  return <div>{element}</div>
}

exports.onServiceWorkerUpdateReady = () => window.location.reload(true);